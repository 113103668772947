<template>
  <div class='dropdown-login' :class="{ 'visible': isVisible }"
    :style="`--width: ${ width ? width + 'px' : '100%' }`">
    <div class="content-wrapper">
      <h3 class="title">{{ $t('navbar.userMenu.loginDropdown.title') }}</h3>
      <h5 class="subtitle">{{ $t('navbar.userMenu.loginDropdown.subtitle')  }}</h5>
      <div class="form">
        <base-input
          ref="emailInput"
          :type="'email'"
          :hasError="emailInput.hasError"
          :placeholderText="$t('navbar.userMenu.loginDropdown.inputs.email')"
          @input="handleInput('email', ...arguments)" />
        <base-input
          ref="passwordInput"
          :type="'password'"
          :hasError="passwordInput.hasError"
          :placeholderText="$t('navbar.userMenu.loginDropdown.inputs.password')"
          @input="handleInput('password', ...arguments)"/>

        <router-link to="/users/request-password-reset" class="forgotten-password">
          {{ $t('navbar.userMenu.loginDropdown.forgottenPassword') }}
        </router-link>
        <base-button
          :text="$t('navbar.userMenu.loginDropdown.login')"
          @clicked="handleLoginBtnClick"
        />
        <span class="not-member" v-html="$t('navbar.userMenu.loginDropdown.registration')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseInput from '@/components/shared/elements/inputs/BaseInput';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'DropdownLogin',
  props: {
    width: {
      type: Number,
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    BaseInput,
    BaseButton,
  },
  data: () => ({
    emailInput: {
      value: undefined,
      hasError: false,
    },
    passwordInput: {
      value: undefined,
      hasError: false,
    },
  }),
  created() {
    document.addEventListener('keyup', this.handleKeyPress);
  },
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
  },
  methods: {
    ...mapActions({
      login: 'users/login',
      saveLoginError: 'users/saveLoginError',
    }),
    handleInput(key, value) {
      switch (key) {
        case 'email':
          this.emailInput.value = value;
          break;
        case 'password':
          this.passwordInput.value = value;
          break;
        default:
          break;
      }
    },
    validateLoginForm() {
      if (this.emailInput.value === undefined || this.emailInput.length === 0) {
        this.emailInput.hasError = true;
      } else {
        this.emailInput.hasError = false;
      }

      if (this.passwordInput.value === undefined || this.passwordInput.value.ength === 0) {
        this.passwordInput.hasError = true;
      } else {
        this.passwordInput.hasError = false;
      }
    },
    resetInputs() {
      this.$refs.emailInput.localInputValue = undefined;
      this.$refs.passwordInput.localInputValue = undefined;
    },
    handleLoginBtnClick() {
      this.validateLoginForm();

      if (!this.emailInput.hasError && !this.passwordInput.hasError) {
        const requestObj = {
          email: this.emailInput.value,
          password: this.passwordInput.value,
        };

        if (this.getCurrentCart !== undefined) {
          requestObj.cart = this.getCurrentCart._id;
        }

        this.login(requestObj).catch((error) => {
          this.resetInputs();

          this.saveLoginError(error.data.message);

          if (this.$route.name !== 'LoginScreen') {
            this.$router.push('/users/login');
          }
        });
      }
    },
    handleKeyPress(e) {
      if (e.key === 'Enter' && this.isVisible) {
        this.handleLoginBtnClick();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyPress);
  },
};
</script>

<style lang='scss' scoped>

.dropdown-login {
  padding: 0;
  position: absolute;
  top: 100%;
  right: 0;
  width: var(--width);
  max-height: 0;
  overflow: hidden;
  box-shadow: $lightDropdownShadow;
  border-radius: $globalBorderRadius;
  transition: max-height 0.6s ease-in-out;
  text-align: center;

  .content-wrapper {
    padding: 24px 25px;
    transition: $transitionBase;
    background-color: $white;
  }

  &.visible {
    max-height: 1000px;
  }
}

.title {
  font-weight: 700;
  color: $titlesBlueColor;
  font-size: 20px;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.subtitle {
  font-size: 10px;
  font-weight: 500;
  margin: 0 0 10px;
  line-height: 13px;
  padding: 0 15px;
}

.base-input {
  &:first-of-type {
    margin: 0 0 10px;
  }
}

.forgotten-password {
  font-size: 12px;
  display: block;
  margin: 7px 0 12px;
}

.not-member {
  font-size: 10px;

  &::v-deep {
    a {
      text-transform: uppercase;
    }
  }
}

.button-base {
  width: 100%;
}
</style>
